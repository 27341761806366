<!--  -->
<template>
  <div class="main has-header">
    <mt-header fixed title="关于">
      <router-link to slot="left">
        <mt-button icon="back" @click.native="$router.back(-1)"></mt-button>
      </router-link>
    </mt-header>
    <div class="container">
      <p>{{isXyk}}是针对企业端平台及自由职业者（推客）新推出的一款移动端产品，用户可通过{{isXyk}}平台查询自己的收入、流水记录，电子合同签约，电子合同预览等服务。平台通过对接园区内政府税务机关以及工商管理部门，为自由职业者解决开发票难、报税难、合同签署困难、远程在线简易注册个体工商户等问题，通过{{isXyk}}平台，企业用户不论在何时何地，都可快速进行管理操作，提高工作效率</p>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      isXyk:window.location.origin.indexOf('h5.kylincloud-saas') > -1 ?'麒麟云服' :'僖游客'
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {} //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.container {
  padding: 10px;
}

.container h2 {
  color:#000;
  padding-bottom: 21px;
  font-weight: normal;
}
.container p {
  color:#333;
  line-height: 26px;
}
</style>